import React from "react";
import AWS_IMAGE from "../../assets/images/aws.svg";
import SLS_IMAGE from "../../assets/images/sls.svg";
import DEVOPS_IMAGE from "../../assets/images/devops.svg";
import MICROSERVICES_IMAGE from "../../assets/images/microservices.svg";
import FINOPS_IMAGE from "../../assets/images/finops.png";
import COMPLIANCE_IMAGE from "../../assets/images/compliance.svg";

const Services = (props) => {
    const serviceItems = servicesData.map(data=>ServiceItem(data))
    return (
        <div id="main">
            <div className="grid">
                <div className="col-12 headline margin-top-3em">Our Areas of Expertise</div>
                {serviceItems}
            </div>
        </div>
    )
}

const ServiceItem = (props) => {
    return (
        <div className="col-4_md-12 feature unify" key={props.id}>
            <div className="image"><img alt="" src={props.image} /></div>
            <div className="headline">{props.title}</div>
            <div className="body">{props.description}</div>
        </div>
    )
}

const servicesData = [
    {
        id: 1,
        image: AWS_IMAGE,
        title: "Cloud Management",
        description: "Our team of AWS experts guides companies to operate on the cloud to enable a new level of automation, cost optimization, capacity overflow, failover, policy enforcement and portability"
    },
    {
        id: 2,
        image: MICROSERVICES_IMAGE,
        title: "Secure AI Solutions",
        description: "Leverage your own data and network to interact with AI models, enhancing control and customization in your AI-driven solutions."
    },
    {
        id: 3,
        image: SLS_IMAGE,
        title: "Serverless Architecture",
        description: "Serverless computing help companies to shift more of traditional operational responsibilities to Cloud providers, enjoy the true benefits of running on Cloud"
    },
    {
        id: 4,
        image: COMPLIANCE_IMAGE,
        title: "Compliance Readiness",
        description: "Prepare for compliance with the Health Insurance Portability and Accountability Act (HIPAA) and/or the Service Organization Control 2 (SOC2) framework"
    },
    {
        id: 5,
        image: DEVOPS_IMAGE,
        title: "DevOps",
        description: "Improve the speed, stability, availability, and security of software delivery capability by using modern DevOps technologies"
    },
    {
        id: 6,
        image: FINOPS_IMAGE,
        title: "Cost Optimization",
        description: "FinOps helps organizations optimize cloud spending by managing costs, increasing accountability, and promoting collaboration between finance, operations, and development teams"
    }
] 

export default Services;