import React from "react";
import Offerings from "../../components/offerings/Offerings";
import "./Services.css";

const Services = () => {
  return (
    <React.Fragment>
      <div id="offerings" className="main-content">
        <Offerings />
      </div>
    </React.Fragment>
  );
};

export default Services;