import React from "react";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Routes from "./Routes";
import "./App.css";

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const el = document.getElementById("header");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    // window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top ? document.body.style.paddingTop = `${this.state.height}px` : document.body.style.paddingTop = 0;
  }

  handleScroll(event) {
    this.setState({ scroll: window.scrollY });
  }

  render(){
    const headerClass = this.state.scroll > this.state.top ? "sticky" : "";
    return (
      <div className="App" id="page">
        <Header headerClass={headerClass} />
        <Routes />
        <Footer />
      </div>
    )
  }
}

export default App;
