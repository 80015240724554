import React from "react";
import Slider from "react-slick";
import dayjs from 'dayjs';
import "./News.css";

import MEDIUM_LOGO from "../../assets/images/medium.png";

const News = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 900, // Tablet and smaller
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    arrows: false
                },
            },
            {
                breakpoint: 600, // Mobile landscape and smaller
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    arrows: false
                },
            },
        ],
    };

    return (
        <div id="news">
            <div className="col-12 title">Latest Posts</div>
            <Slider {...settings}>
                {newsData.map(data => (
                    <NewsItem {...data} />
                ))}
            </Slider>
        </div>
    );
};

const NewsItem = (props) => {
    const url = `${props.url}?source=friends_link&sk=d9100636e5b2f54ae14f08db49f1c2b3`
    const isValidDate = props.date && dayjs(props.date).isValid();
    const formattedDate = isValidDate ? dayjs(props.date).format("MMMM D, YYYY") : '';
    return (
        <div className="entry" key={props.id}>
            <div className="box" target="_blank" rel="noopener noreferrer" href={url}>
                <img className="news-image" alt="" src={props.image} />
                {isValidDate && <span className="link"><a target="_blank" rel="noopener noreferrer" href={url}>{formattedDate}</a></span>}
                <div className="headline">{props.title}</div>
                <div className="body">{props.description}</div>
            </div>
        </div>
    )
}

const newsData = [
    {
        id: 1,
        image: MEDIUM_LOGO,
        title: "Introduction to AWS CDK with EC2 Example",
        date: "2019-07-17",
        description: "Infrastructure as a Code is the process of managing and provisioning system resources through human and machine readable.",
        url: "https://medium.com/tysonworks/introduction-to-aws-cdk-with-ec2-example-2355505c70b3"
    },
    {
        id: 2,
        image: MEDIUM_LOGO,
        title: "Concurrency in Boto3",
        date: "2019-07-17",
        description: "Asyncio provides set of tools for concurrent programming in Python. In a very simple sense it does this by having an event loop",
        url: "https://medium.com/tysonworks/concurrency-with-boto3-41cfa300aab4"
    },
    {
        id: 3,
        image: MEDIUM_LOGO,
        title: "Automated Cloudformation Drift Detection",
        date: "2019-07-21",
        description: "Cloudformation is a tool for provisioning AWS resources using template files.  When you need to make a change to the configuration",
        url: "https://medium.com/tysonworks/automated-cloudformation-drift-detection-4f0f53d50968"
    },
    {
        id: 4,
        image: MEDIUM_LOGO,
        title: "Deploy Go Applications to ECS using AWS CDK",
        date: "2019-07-23",
        description: "AWS CDK, Cloud Development Kit, a tool for managing AWS resources as Infrastructure as Code has become generally available just weeks ago",
        url: "https://medium.com/tysonworks/deploy-go-applications-to-ecs-using-aws-cdk-1a97d85bb4cb"
    },
    {
        id: 5,
        image: MEDIUM_LOGO,
        title: "Create and Deploy Simple URL Shortener with AWS CDK and DocumentDB",
        date: "2019-08-03",
        description: "Simple URL shortener application using AWS CDK, DocumentDB, Lambda and API Gateway",
        url: "https://medium.com/tysonworks/create-and-deploy-simple-url-shortener-with-aws-cdk-and-documentdb-875ab99d51f5"
    },
    {
        id: 6,
        image: MEDIUM_LOGO,
        title: "DNS Failover with Route53",
        date: "2019-08-04",
        description: "Route 53‘s DNS Failover feature gives you the power to monitor your website and automatically route your visitors to backup site",
        url: "https://medium.com/tysonworks/dns-failover-with-route53-cc3427a3629a"
    },
    {
        id: 7,
        image: MEDIUM_LOGO,
        title: "Managed ETL using AWS Glue and Spark",
        date: "2019-10-30",
        description: "ETL, Extract, Transform and Load workloads are becoming really popular lately. Increasing number of companies are looking for solutions",
        url: "https://medium.com/tysonworks/managed-etl-using-aws-glue-and-spark-62a92292f19d"
    },
    {
        id: 8,
        image: MEDIUM_LOGO,
        title: "Manage Batch Jobs with AWS Batch",
        date: "2019-11-14",
        description: "AWS Batch allows us to run batch workloads without managing any compute resources. Although newer services such as ECS might be more appealing",
        url: "https://medium.com/tysonworks/manage-batch-jobs-with-aws-batch-1f91229b1b6e"
    },
    {
        id: 9,
        image: MEDIUM_LOGO,
        title: "Create Wake-Up Call Service With AWS Pinpoint, Lambda and CDK",
        date: "2020-02-20",
        description: "Wake-up call service is exactly what you would imagine, someone will call you to wake you up",
        url: "https://medium.com/tysonworks/create-your-own-wake-up-call-service-with-aws-pinpoint-lambda-and-cdk-ef850af3898f"
    },
    {
        id: 10,
        image: MEDIUM_LOGO,
        title: "Highly Available and Scalable Wordpress on AWS",
        date: "2020-02-21",
        description: "The initial version of Wordpress was released in May 2003 and it has been nearly 17 years. Wordpress is still going strong",
        url: "https://medium.com/tysonworks/highly-available-and-scalable-wordpress-installation-on-aws-1fa27379c5b0"
    },
    {
        id: 11,
        image: MEDIUM_LOGO,
        title: "Serverless Kubernetes with Amazon EKS",
        date: "2020-03-09",
        description: "The term Serverless does not mean that there are no servers involved at all. As many people are still confused with the fairly",
        url: "https://medium.com/tysonworks/serverless-kubernetes-with-amazon-eks-a2d8fb0f8333"
    },
    {
        id: 12,
        image: MEDIUM_LOGO,
        title: "Deploy Containers using Docker ECS Plugin",
        date: "2020-07-26",
        description: "Containers are good, we love them. Amazon ECS is also good, and we like it, but the least likable aspects of ECS",
        url: "https://medium.com/tysonworks/deploy-containers-to-aws-using-docker-ecs-plugin-d071e07ec62a"
    },
    {
        id: 13,
        image: MEDIUM_LOGO,
        title: "Default Encryption for new S3 Objects",
        date: "2023-01-08",
        description: "AWS recently announced a new feature for its Simple Storage Service (S3). This feature encrypts all new objects by default",
        url: "https://medium.com/tysonworks/default-encryption-for-new-s3-objects-6639b15960d2"
    },
    {
        id: 14,
        image: MEDIUM_LOGO,
        title: "What is Amazon Keyspaces?",
        date: "2023-02-04",
        description: "Amazon Keyspaces (for Apache Cassandra) is a scalable, highly available, and managed Apache Cassandra–compatible",
        url: "https://medium.com/tysonworks/what-is-amazon-keyspaces-and-why-you-should-consider-it-34f1f8423385"
    },
    {
        id: 15,
        image: MEDIUM_LOGO,
        title: "Opt Out For AWS Lambda Updates",
        date: "2023-02-05",
        description: "AWS Lambda automatically patches the underlying infrastructure for your functions  so you don’t have to worry about managing",
        url: "https://medium.com/tysonworks/opt-out-for-aws-lambda-updates-why-and-how-5839a714e83"
    },
    {
        id: 16,
        image: MEDIUM_LOGO,
        title: "Healthcare Workloads on AWS",
        date: "2023-02-12",
        description: "Cloud computing has become an essential tool for healthcare organizations to store, process, and manage their data, and",
        url: "https://medium.com/tysonworks/healthcare-workloads-on-aws-eccdbb98b57"
    },
    {
        id: 17,
        image: MEDIUM_LOGO,
        title: "Tagging Strategies on AWS",
        date: "2023-02-15",
        description: "Tags are metadata that you can assign to Amazon Web Services(AWS) resources such as EC2 instances, S3 buckets, and RDS databases",
        url: "https://medium.com/tysonworks/aws-tagging-strategies-236c4c55a6ab"
    },
    {
        id: 18,
        image: MEDIUM_LOGO,
        title: "CDK Migration Guide",
        date: "2023-02-16",
        description: "The CDK has been released in two major versions, v1 and v2. CDK v1 entered maintenance on June 1, 2022. Support for CDK v1 will end soon",
        url: "https://medium.com/tysonworks/cloud-development-kit-cdk-migration-guide-3c4471c06434"
    },
    {
        id: 19,
        image: MEDIUM_LOGO,
        title: "Lambda Function URLs",
        date: "2023-02-22",
        description: "AWS Lambda Function URLs are unique HTTP endpoints that you can create using AWS Console, SDK or any other IaC tool. These URLs are used to",
        url: "https://medium.com/tysonworks/aws-lambda-function-urls-dc1c7112937e"
    },
    {
        id: 20,
        image: MEDIUM_LOGO,
        title: "DynamoDB Shell",
        date: "2023-02-20",
        description: "Recently, AWS announced the release of ddbsh, a command line interface that was inspired by similar projects like MySQL CLIs. With ddbsh...",
        url: "https://medium.com/tysonworks/introduction-to-dynamodb-shell-ae78fad71ca8"
    },
    {
        id: 21,
        image: MEDIUM_LOGO,
        title: "Centralized Backups",
        date: "2023-02-24",
        description: "AWS Backup allows you to create backup policies that automate the backup of your data across AWS services such as Amazon EC2, Amazon EBS...",
        url: "https://medium.com/tysonworks/aws-backups-for-centralized-backup-solution-30f5ab308cc"
    },
    {
        id: 22,
        image: MEDIUM_LOGO,
        title: "Why companies fail on cloud?",
        date: "2023-03-02",
        description: "Despite the many benefits that cloud computing offers, not all companies that move to the cloud are successful. As a cloud consultant...",
        url: "https://medium.com/tysonworks/why-companies-fail-on-cloud-a-perspective-from-a-cloud-consultant-e8584be7d88c"
    },
    {
        id: 23,
        image: MEDIUM_LOGO,
        title: "Manage MongoDB Atlas with CDK",
        date: "2023-03-04",
        description: "MongoDB Atlas is a fully-managed cloud-based database service offered by MongoDB. It offers a variety of features such as...",
        url: "https://medium.com/tysonworks/manage-mongodb-atlas-deployments-with-aws-cdk-c29e816583e8"
    },
    {
        id: 24,
        image: MEDIUM_LOGO,
        title: "Stay Up to Date with Daily AWS Updates",
        date: "2023-03-08",
        description: "Keeping up with the latest features and updates in Amazon Web Services (AWS) can be challenging. AWS releases new...",
        url: "https://medium.com/tysonworks/stay-up-to-date-with-daily-aws-updates-delivered-to-your-inbox-122f36f03286"
    },
    {
        id: 25,
        image: MEDIUM_LOGO,
        title: "AWS SQS Pitfalls & Best Practices",
        date: "2023-03-19",
        description: "SQS is a fully managed, scalable, and reliable message queue service designed to help decouple and scale microservices...",
        url: "https://medium.com/tysonworks/sqs-pitfalls-best-practices-e8eb8f099ead"
    },
    {
        id: 26,
        image: MEDIUM_LOGO,
        title: "AWS SES vs AWS Pinpoint",
        date: "2023-03-22",
        description: "(AWS) offers two services for sending emails: Simple Email Service (SES) and Pinpoint. While both services allow you to...",
        url: "https://medium.com/@tysonworks/aws-ses-vs-aws-pinpoint-14c4099ef9d6"
    },
    {
        id: 27,
        image: MEDIUM_LOGO,
        title: "Why Multi-Cloud is Not a Good Idea?",
        date: "2024-02-13",
        description: "The appeal of adopting a multi-cloud strategy in today’s dynamic and ever-evolving cloud landscape is quite significant. This strategic...",
        url: "https://medium.com/tysonworks/why-multi-cloud-is-not-a-good-idea-6bb65f294921"
    },
    {
        id: 28,
        image: MEDIUM_LOGO,
        title: "Which Container Solution is Right for You?",
        date: "2024-02-25",
        description: "In recent years, the adoption of containers has revolutionized the way applications are developed, deployed, and managed. This rise in ...",
        url: "https://medium.com/tysonworks/hosting-container-workloads-on-aws-here-are-your-options-59087c7072e7"
    },

].reverse()

export default News;