import React from "react";
import "./Team.css";

import CAGDAS_PROFILE from "../../assets/images/cagdas_profile.jpg";

const Team = (props) => {
    return (
        <div id="team">
            <div className="grid section">
                <div className="col-1 sm-hidden"></div>
                <div className="col-10_sm-12">
                    <h2 className="head">Meet our teammates</h2>
                </div>
            </div>
            <div id="members" className="grid section grid-center">
                {membersData.map(data=>Member(data))}
            </div>
        </div>
    )
}

const Member = (props)=> {
    return (
        <div className="member col-3_md-4_sm-6_xs-12" key={props.id}>
            <div className="profile-image">
                <img alt="" src={props.image} />
            </div>
            <h3>{props.name}</h3>
            <h4>{props.role}</h4>
            {props.certs.map(cert=><a key={cert.title} href={cert.url} target="_blank" rel="noopener noreferrer"><li>{cert.title}</li></a>)}
        </div>
    )
}

const membersData = [
    {
        id: 0,
        image: CAGDAS_PROFILE,
        name: "Cagdas Ozbey",
        role: "Senior Tech Lead",
        certs: [
            {title: "AWS Solutions Architect Professional", "url": "https://tysonworks.com/certs/cagdas_sap.pdf"},
            {title: "AWS Devops Professional", "url": "https://tysonworks.com/certs/cagdas_devops.pdf"},
            {title: "Certified Kubernetes Administrator", "url": "https://tysonworks.com/certs/cagdas_cka.pdf"},
            {"title": "AWS Solutions Architect Associate", "url": "https://tysonworks.com/certs/cagdas_sa.pdf"},
            {"title": "AWS Cloud Practioner", "url": "https://tysonworks.com/certs/cagdas_cp.pdf"}
        ]
    },
    {
        id: 1,
        image: "https://via.placeholder.com/150x191.png?text=+",
        name: "Sekhar Mandava",
        role: "Cloud Developer",
        certs: [
            {"title": "AWS Solutions Architect Associate", "url": "https://tysonworks.com/certs/sek_sa.pdf"},
            {"title": "AWS Cloud Practioner", "url": "https://tysonworks.com/certs/sek_cp.pdf"}
        ]
    },
];

export default Team;