import React from "react";
import Company from "../../components/company/Company";
import Team from "../../components/team/Team";
import Location from "../../components/location/Location";
import CaseStudies from "../../components/casestudies/CaseStudies";
import Customers from "../../components/customers/Customers";
import SLS_IMAGE from "../../assets/images/sls.svg";
import "./ServiceDetail.css";
import { useParams } from "react-router-dom";

const ServiceDetail = (props) => {
  const { id } = useParams();
  console.log(id);
  const data = services.find((x) => x.id === id);
  if (data) {
    return (
      <React.Fragment>
        <div id="about" className=""></div>
        <div id="mission">
          <div id="mission-top" className="grid section">
            <div className="col-10_sm-12" data-push-left="off-1_sm-0">
              <div>
              <img className="about-image" alt="" src={data.image} />
                <p className="body">
                  <br />
                  Our mission is to break down the complexity of the cloud and
                  provide a whole new level of flexibility and stability. We are
                  here to enhance your cloud journey, so your company can evolve
                  at a faster rate.
                  <br /> <br />
                  Cloud computing can unlock new opportunities for businesses,
                  and many companies are trying to figure out how they can
                  benefit from the cloud. At TysonWorks, you’ll be working hand
                  in hand with cloud experts who will apply years of experience
                  and best practices to your cloud implementation. We will guide
                  you through the different cloud offerings, and help your
                  company to understand the effectiveness of the cloud.
                  <br /> <br />
                  We provide a comprehensive range of professional services to
                  help organizations achieve their business objectives
                  efficiently and cost-effectively. Specializing in Cloud
                  Management, Cloud Migration, Serverless Architecture, Cost
                  Optimization, and DevOps, TysonWorks is dedicated to helping
                  organizations make the most of their AWS investment.
                  <br /> <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="about" className="main-content"></div>
      </React.Fragment>
    );
  }
};

const services = [
  {
    id: "management",
    title: "Cloud Management",
    text: "Some Text",
    image: SLS_IMAGE,
  },
];

export default ServiceDetail;
