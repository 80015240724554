import React from "react";
import Company from "../../components/company/Company";
import Team from "../../components/team/Team";
import Location from "../../components/location/Location";
import CaseStudies from "../../components/casestudies/CaseStudies";
import Customers from "../../components/customers/Customers";
import WhyChooseTysonWorks from "../../components/whychoosetysonworks/WhyChooseTysonWorks";
import "./About.css";

const About = (props) => {
  return (
    <React.Fragment>
      <div id="about" className="main-content">
        <Company />
        <WhyChooseTysonWorks />
        <CaseStudies />
      </div>
    </React.Fragment>
  );
};

export default About;
