import React from "react";
import "./Contact.css";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ContactFormURL = 'https://5lh9umqz6j.execute-api.eu-west-1.amazonaws.com/prod/';

class Contact extends React.Component{
    render() {
        return (
            <React.Fragment>
                <div id="contact" className="main-content">
                    <div className="body">
                        <b className="headline">Contact Us</b>
                        <br /> <br />
                        Take the Next Step in Advancing Your Cloud Strategy
                        <div id="contact-form" className="col-12">
                            {form()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function form() {
    return (
        <Formik
            initialValues={{
                name: "",
                company: "",
                email: "",
                message: ""
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                    .required('Name is required'),
                email: Yup.string()
                    .email('Email is invalid')
                    .required('Email is required'),
                message: Yup.string()
                    .required('Message is required')
            })}
            onSubmit={fields => {
                // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
                const response = {
                    name: fields.name,
                    subject: fields.company,
                    email: fields.email,
                    message: fields.message
                };
                fetch(ContactFormURL, {
                    body: JSON.stringify(response),
                    method: 'POST'
                }).then(success=>{
                    alert("We have your message, our team will be in touch soon.");
                    window.location.reload();
                })
            }}
            render={({ errors, status, touched }) => (
                <Form>
                        <div className="body row">
                            <div className="form-group col">
                                <label htmlFor="name">Name</label>
                                <Field name="name" type="text" className={'form-control form-input' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>
                            
                            <div className="form-group col">
                                <label htmlFor="email">Email</label>
                                <Field name="email" type="text" className={'form-control form-input' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <Field name="message" component="textarea" className={'form-control form-input contact-message' + (errors.message && touched.message ? ' is-invalid' : '')} />
                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="button-solid">Submit</button>
                        </div>
                </Form>
            )}
            />
    )
}

export default Contact;