import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

import LOGO_WHITE from "../../assets/images/tw-logo1.png";

const Footer = (props) => {
    return (
        <div id="footer">
            <div className="grid">
                <div className="col-7_md-12 left">
                    <div className="logo"><img id="footer-logo" alt="" src={LOGO_WHITE} /></div>
                    <div className="copyright"><a href="/privacy">Privacy Policy</a> <a href="/terms">Terms of Service</a></div>
                    <div id="copyright-text">&copy; TysonWorks 2024 All rights reserved.</div>
                </div>
                <div className="col-5_md-12 right">
                    {/* <div className="col-1 sm-hidden"></div> */}
                    <div className="find-out">
                        <a href="mailto:contact@tysonworks.com">contact@tysonworks.com</a>
                        <br />
                        <a href="tel:+12018700763" className="contact-phone">+1 (201) 870-0763</a>
                        <br />
                        <a className="contact-address">
                            TysonWorks LLC - 228 Park Ave S New York, NY 10003
                        </a>
                    </div>
                    <div className="links">
                        <a href="https://medium.com/tysonworks" target="_blank" rel="noopener noreferrer">Blog</a>
                        <a href="https://github.com/tysonworks" target="_blank" rel="noopener noreferrer">Github</a>
                        <a href="https://twitter.com/tysonworks" target="_blank" rel="noopener noreferrer">Twitter</a>
                        <a href="https://linkedin.com/company/tysonworks" target="_blank" rel="noopener noreferrer">Linkedin</a>
                        <a href="https://linkedin.com/company/tysonworks/jobs" target="_blank" rel="noopener noreferrer">Career</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;