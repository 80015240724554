import React from "react";
import { Route, Switch } from 'react-router-dom';
import Home from "./pages/home/Home";
import About from "./pages/about/About"
import Contact from "./pages/contact/Contact";
import Jobs from "./pages/jobs/Jobs";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import ServiceDetail from "./pages/servicedetail/ServiceDetail";
import Services from "./pages/services/Services";


const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact render={() => <Home />} />
            <Route path="/about" exact render={() => <About />} />
            <Route path="/contact" exact render={() => <Contact />} />
            <Route path="/jobs" exact render={() => <Jobs />} />
            <Route path="/privacy" exact render={() => <Privacy />} />
            <Route path="/terms" exact render={() => <Terms />} />
            <Route path="/services/:id" render={()=> <ServiceDetail />} />
            <Route path="/services" exact render={() => <Services />} />
            <Route exact render={() => <Home />} />
        </Switch>
    )
}

export default Routes;
