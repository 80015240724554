import React from "react";
import Splash from "../../components/splash/Splash";
import Services from "../../components/services/Services";
import News from "../../components/news/News";
import Customers from "../../components/customers/Customers";

const Home = (props) => {
    return (
        <React.Fragment>
            <Splash />
            <Services />
            <Customers />
            <News />
        </React.Fragment>
    )
}

export default Home;