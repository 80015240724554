import React from "react";
import "./Jobs.css";


const Jobs = (props) => {
    return (
        <div className="main-content">
            <div id="jobs">
                <div id="job-list" className="grid section">
                    {jobsData.map(JobItem)}
                </div>
            </div>
        </div>
    );
}


const JobItem = (props) => {
    return (
        <a target="_blank" rel="noopener noreferrer" href={props.url} className="job" key={props.id}>
            <div className="col-12 row">
                <span className="cell title">{props.title}</span>
                <span className="cell location">{props.location}</span>
                <span className="cell apply">Learn More</span>
            </div>
        </a>
    )
}

const jobsData = [
    {id: 1, title: "Software Engineer - Frontend/React", location: "Remote", url: "https://linkedin.com/company/tysonworks"},
    {id: 2, title: "Jr. Sales Engineer - Software", location: "Remote", url: "https://linkedin.com/company/tysonworks"}
]

export default Jobs;