import React from "react";
import "./Terms.css";

const TermsOfUse = props => {
  return (
    <div id="mission">
      <div id="mission-top" className="grid section">
        <div className="col-6_sm-12" data-push-left="off-3_sm-0">
          <h2 className="head">Terms of Use</h2>
        </div>
        <div className="col-10_sm-12" data-push-left="off-1_sm-0">
          <div>
            <p>
              These terms and conditions apply between you, the User of this Website (including any sub-domains, unless
              expressly excluded by their own terms and conditions), and TysonWorks the owner and operator of this
              Website. Please read these terms and conditions carefully, as they affect your legal rights. Your
              agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use
              of the Website. If you do not agree to be bound by these terms and conditions, you should stop using the
              Website immediately.
            </p>

            <p>
              In these terms and conditions, <b>User</b> or <b>Users</b> means any third party that accesses the Website
              and is not either (i) employed by TysonWorks and acting in the course of their employment or (ii) engaged
              as a consultant or otherwise providing services to TysonWorks and accessing the Website in connection with
              the provision of such services.
            </p>

            <p>
              You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms
              and conditions, you represent and warrant that you are at least 18 years of age.
            </p>

            <p>
              <b>Intellectual property and acceptable use</b>
            </p>

            <ul>
              <li>
                All Content included on the Website, unless uploaded by Users, is the property of TysonWorks our
                affiliates or other relevant third parties. In these terms and conditions, Content means any text,
                graphics, images, audio, video, software, data compilations, page layout, underlying code and software
                and any other form of information capable of being stored in a computer that appears on or forms part of
                this Website, including any such content uploaded by Users. By continuing to use the Website you
                acknowledge that such Content is protected by copyright, trademarks, database rights and other
                intellectual property rights. Nothing on this site shall be construed as granting, by implication,
                estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on the
                site without the owner's prior written permission
              </li>
              <li>
                You may, for your own personal, non-commercial use only, do the following:
                <br />
                retrieve, display and view the Content on a computer screen
              </li>
              <li>
                You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any Content
                without the written permission of TysonWorks
              </li>
            </ul>

            <p>
              <b>Prohibited use</b>
            </p>

            <ul>
              <li>
                You may not use the Website for any of the following purposes:
                <br />
                in any way which causes, or may cause, damage to the Website or interferes with any other person's use
                or enjoyment of the Website;
                <br />
                in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise
                objectionable or in breach of any applicable law, regulation, governmental order;
                <br />
                making, transmitting or storing electronic copies of Content protected by copyright without the
                permission of the owner.
              </li>
            </ul>

            <div>
              <p>
                <b>Links to other websites</b>
              </p>
              <ul>
                <li>
                  This Website may contain links to other sites. Unless expressly stated, these sites are not under the
                  control of TysonWorks or that of our affiliates.
                </li>
                <li>
                  We assume no responsibility for the content of such Websites and disclaim liability for any and all
                  forms of loss or damage arising out of the use of them.
                </li>
                <li>
                  The inclusion of a link to another site on this Website does not imply any endorsement of the sites
                  themselves or of those in control of them.
                </li>
              </ul>
            </div>

            <div>
              <p>
                <b>Privacy Policy and Cookies Policy</b>
              </p>

              <ul class="clauses">
                <li>
                  Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are incorporated
                  into these terms and conditions by this reference. To view the Privacy Policy and Cookies Policy,
                  please click on the following: <a target="_blank" rel="noopener noreferrer" href="https://tysonworks.com/privacy">http://tysonworks.com/privacy</a>
                </li>
              </ul>
            </div>

            <p>
              <b>Availability of the Website and disclaimers</b>
            </p>

            <ul class="clauses">
              <li>
                Any online facilities, tools, services or information that TysonWorks makes available through the
                Website (the <b>Service</b>) is provided "as is" and on an "as available" basis. We give no warranty
                that the Service will be free of defects and/or faults. To the maximum extent permitted by the law, we
                provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information,
                compatibility and satisfactory quality. TysonWorks is under no obligation to update information on the
                Website.
              </li>
              <li>
                Whilst TysonWorks uses reasonable endeavours to ensure that the Website is secure and free of errors,
                viruses and other malware, we give no warranty or guaranty in that regard and all Users take
                responsibility for their own security, that of their personal details and their computers.
              </li>
              <li>TysonWorks accepts no liability for any disruption or non-availability of the Website.</li>
              <li>
                reserves the right to alter, suspend or discontinue any part (or the whole of) the Website including,
                but not limited to, any products and/or services available. These terms and conditions shall continue to
                apply to any modified version of the Website unless it is expressly stated otherwise.
              </li>
            </ul>

            <p>
              <b>Limitation of liability</b>
            </p>

            <ul class="clauses">
              <li>
                Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or
                personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or your
                liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your
                liabilities in any way that is not permitted under applicable law.
              </li>
              <li>
                We will not be liable to you in respect of any losses arising out of events beyond our reasonable
                control.
              </li>
              <li>
                To the maximum extent permitted by law, TysonWorks accepts no liability for any of the following:
                <p>
                  any business losses, such as loss of profits, income, revenue, anticipated savings, business,
                  contracts, goodwill or commercial opportunities;
                  <br />
                  loss or corruption of any data, database or software;
                  <br />
                  any special, indirect or consequential loss or damage.
                </p>
              </li>
            </ul>

            <p>
              <b>General</b>
            </p>

            <ul class="clauses">
              <li>
                You may not transfer any of your rights under these terms and conditions to any other person. We may
                transfer our rights under these terms and conditions where we reasonably believe your rights will not be
                affected.
              </li>
              <li>
                These terms and conditions may be varied by us from time to time. Such revised terms will apply to the
                Website from the date of publication. Users should check the terms and conditions regularly to ensure
                familiarity with the then current version.
              </li>
              <li>
                These terms and conditions together with the Privacy Policy and Cookies Policy contain the whole
                agreement between the parties relating to its subject matter and supersede all prior discussions,
                arrangements or agreements that might have taken place in relation to the terms and conditions.
              </li>
              <li>
                The Contracts (Rights of Third Parties) Act 1999&nbsp;shall not apply to these terms and conditions and
                no third party will have any right to enforce or rely on any provision of these terms and conditions.
              </li>
              <li>
                If any court or competent authority finds that any provision of these terms and conditions (or part of
                any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the
                extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of
                these terms and conditions will not be affected.
              </li>
              <li>
                Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be
                deemed a waiver of that, or any other, right or remedy.
              </li>
            </ul>

            <p>
              <b>TysonWorks details</b>
            </p>

            <ul class="clauses">
              <li>
                TysonWorks is a company incorporated in with registered number whose registered address is and it
                operates the Website <a target="_blank" rel="noopener noreferrer" href="https://tysonworks.com">https://tysonworks.com</a>
                <p>
                  You can contact TysonWorks via email: <a target="_blank" rel="noopener noreferrer" href="http://tysonworks.com/contact">http://tysonworks.com/contact</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
