import React from "react";
import "./CaseStudies.css";

import MEDICHANDS_LOGO from "../../assets/images/medichands.png";
import OCULUSHEALTH_LOGO from "../../assets/images/oculushealth.png";
import ZIRAN_LOGO from "../../assets/images/ziran.png";

const CaseStudies = () => {
  const caseData = [
    {
      id: 0,
      image: OCULUSHEALTH_LOGO,
      name: "Oculus Health",
      client: "Healthcare",
      description: "",
      url: "https://tysonworks.com/case-studies/oculushealth.pdf",
    },
    {
      id: 1,
      image: MEDICHANDS_LOGO,
      name: "MedicHands",
      client: "Healthcare",
      description: "",
      url: "https://tysonworks.com/case-studies/medichands.pdf",
    },
    {
      id: 2,
      image: ZIRAN_LOGO,
      name: "Ziran Biopharmaceutical",
      client: "Biotechnology",
      description: "",
      url: "https://tysonworks.com/case-studies/ziran.pdf",
    },
  ];

  return (
    <section id="team">
      <div className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">Read Our Case Studies</h2>
        </div>
      </div>
      <div id="members" className="grid section grid-center">
        {caseData.map((caseItem) => (
          <CaseStudy key={caseItem.id} caseItem={caseItem} />
        ))}
      </div>
    </section>
  );
};

const CaseStudy = ({ caseItem }) => {
  const { url, image, name, client, description = "" } = caseItem;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="col-3_md-6">
      <div>
        <div className="">
          <img src={image} alt={name} />
        </div>
        <h3>{name}</h3>
        <h4>{client}</h4>
      </div>
    </a>
  );
};

export default CaseStudies;