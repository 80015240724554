import React from "react";
import "./Privacy.css";

const Privacy = props => {
  return (
    <div id="mission">
      <div id="mission-top" className="grid section">
        <div className="col-6_sm-12" data-push-left="off-3_sm-0">
          <h2 className="head">Privacy Policy</h2>
        </div>
        <div className="col-10_sm-12" data-push-left="off-1_sm-0">
          <div>
            <p>
              Your privacy is important to us. It is TysonWorks' policy to respect your privacy regarding any
              information we may collect from you across our website,{" "}
              <a href="http://tysonworks.com">http://tysonworks.com</a>, and other sites we own and operate.
            </p>
            <p>
              We only ask for personal information when we truly need it to provide a service to you. We collect it by
              fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and
              how it will be used.
            </p>
            <p>
              We only retain collected information for as long as necessary to provide you with your requested service.
              What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well
              as unauthorised access, disclosure, copying, use or modification.
            </p>
            <p>
              We don’t share any personally identifying information publicly or with third-parties, except when required
              to by law.
            </p>
            <p>
              Our website may link to external sites that are not operated by us. Please be aware that we have no
              control over the content and practices of these sites, and cannot accept responsibility or liability for
              their respective privacy policies.
            </p>
            <p>
              You are free to refuse our request for your personal information, with the understanding that we may be
              unable to provide you with some of your desired services.
            </p>
            <p>
              Your continued use of our website will be regarded as acceptance of our practices around privacy and
              personal information. If you have any questions about how we handle user data and personal information,
              feel free to contact us.
            </p>
            <p>
              Where granted by applicable law, you may have the right to request access to the personal data that we
              have collected about you for the purposes of reviewing, modifying, or requesting deletion of the data. You
              may also have the right to request a copy of the personal data that we have collected about you and to
              have any inaccuracies in that data corrected. In certain circumstances, you may also request that we cease
              processing your personal data.
            </p>
            <p>This policy is effective as of 1 January 2020.</p>
          </div>
        </div>
        <div className="col-6_sm-12" data-push-left="off-3_sm-0">
          <h2 className="head">Cookie Policy</h2>
        </div>
        <div className="col-10_sm-12" data-push-left="off-1_sm-0">
          <p>
            <b>What Are Cookies</b>
          </p>

          <p>
            As is common practice with almost all professional websites this site uses cookies, which are tiny files
            that are downloaded to your computer, to improve your experience. This page describes what information they
            gather, how we use it and why we sometimes need to store these cookies. We will also share how you can
            prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites
            functionality.
          </p>

          <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>

          <p>
            <b>How We Use Cookies</b>
          </p>

          <p>
            We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry
            standard options for disabling cookies without completely disabling the functionality and features they add
            to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or
            not in case they are used to provide a service that you use.
          </p>

          <p>
            <b>Disabling Cookies</b>
          </p>

          <p>
            You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for
            how to do this). Be aware that disabling cookies will affect the functionality of this and many other
            websites that you visit. Disabling cookies will usually result in also disabling certain functionality and
            features of the this site. Therefore it is recommended that you do not disable cookies.
          </p>

          <p>
            <b>The Cookies We Set</b>
          </p>

          <ul>
            <p>
              <b>Site preferences cookies</b>
            </p>
            <p>
              In order to provide you with a great experience on this site we provide the functionality to set your
              preferences for how this site runs when you use it. In order to remember your preferences we need to set
              cookies so that this information can be called whenever you interact with a page is affected by your
              preferences.
            </p>
          </ul>
          <p>
            <b>Third Party Cookies</b>
          </p>
          <p>
            In some special cases we also use cookies provided by trusted third parties. The following section details
            which third party cookies you might encounter through this site.
          </p>

          <p>
            Third party analytics are used to track and measure usage of this site so that we can continue to produce
            engaging content. These cookies may track things such as how long you spend on the site or pages you visit
            which helps us to understand how we can improve the site for you.
          </p>

          <p>
            <strong>More Information</strong>
          </p>

          <p>
            If you are looking for more information then you can contact us through one of our preferred contact
            methods:
          </p>

          <p>
            By visiting this link: <a target="_blank" rel="noopener noreferrer" href="https://tysonworks.com/contact">https://tysonworks.com/contact</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
