import React from "react";
import AWS_IMAGE from "../../assets/images/aws.svg";
import SLS_IMAGE from "../../assets/images/sls.svg";
import DEVOPS_IMAGE from "../../assets/images/devops.svg";
import MICROSERVICES_IMAGE from "../../assets/images/microservices.svg";
import FINOPS_IMAGE from "../../assets/images/finops.png";
import COMPLIANCE_IMAGE from "../../assets/images/compliance.svg";
import CLOUD_TRAINING_IMAGE from "../../assets/images/cloud-training.png";
import "./Offerings.css";

const Services = (props) => {
    // Data for the new services
    const servicesData = [
        {
            id: 1,
            image: DEVOPS_IMAGE,
            title: "On-Demand Consulting",
            description: "Flexible consulting services tailored to your immediate cloud infrastructure needs.",
            type: "onDemand"
        },
        {
            id: 2,
            image: FINOPS_IMAGE,
            title: "Project Based Consulting",
            description: "Project-specific consulting services for AWS cloud deployments and management.",
            type: "onDemand"
        },
        {
            id: 3,
            image: COMPLIANCE_IMAGE,
            title: "Compliance Readiness",
            description: "Be prepared for SOC2 & HIPAA Compliance with our team of experts",
            type: "onDemand"
        },
        {
            id: 4,
            image: CLOUD_TRAINING_IMAGE,
            title: "Training",
            description: "Comprehensive training programs for AWS cloud services and best practices.",
            type: "onDemand"
        },
        // {
        //     id: 5,
        //     image: AWS_IMAGE,
        //     title: "Starter Package",
        //     price: 3000,
        //     points: [
        //         "For Small Businesses",
        //         "Monitoring",
        //         "Cost Optimization",
        //         "Email Support"
        //     ],
        //     type : "monthly"
        // },
        {
            id: 6,
            image: AWS_IMAGE,
            title: "Starter Package",
            points: [
                "For Startups",
                "Monitoring",
                "Cost Optimization",
                "Email & Slack Support",
                "20 Billable Hours Included Monthly"
            ],
            price: 5000,
            type: "monthly"
        },
        {
            id: 7,
            image: AWS_IMAGE,
            title: "Professional Package",
            points: [
                "For Small Businesses & Startups",
                "All services from the Starter Package",
                "7/24 Monitoring",
                "Email, Slack Support",
                "50 Billable Hours Included Monthly"
            ],
            price: 10000,
            type: "monthly"
        },
        {
            id: 8,
            image: AWS_IMAGE,
            title: "Enterprise Package",
            points: [
                "For More Demanding Businesses",
                "All services from the Professional Package",
                "Email, Slack, Phone Support",
                "Service Level Agreement (SLA)",
                "80 Billable Hours Included Monthly"
            ],
            price: 15000,
            type: "monthly"
        }
    ];



    // Map through each data array to create service items
    const monthlyServiceItems = servicesData.filter(x => x.type === "monthly").map(data => <ServiceItem {...data} />);
    const serviceItems = servicesData.filter(x => x.type === "onDemand").map(data => <ServiceItem {...data} />);

    return (
        <div id="offering" className="grid section">
            {servicesSection(
                "monthly-services",
                "Monthly Packages",
                "Want to minimize DevOps costs and make sure your Cloud infrastructure at the hand of our experts?",
                monthlyServiceItems
            )}
            {servicesSection(
                "services",
                "Consulting & Training Services",
                "Discover our custom consulting solutions and training services for your cloud needs.",
                serviceItems
            )}
        </div>
    )
}

const servicesSection = (sectionId, title, description, serviceItems) => {
    return (
        <div className="grid" id={sectionId}>
            <div className="col-12 headline">{title}</div>
            <p className="body col-12" style={{ textAlign: 'center', margin: 'auto', maxWidth: '800px' }}>
                {description}
            </p>
            <div className="col-12_md-12"></div>
            {serviceItems}
        </div>
    );
};

const ServiceItem = ({ id, image, title, description, points, price, type }) => {
    const pointStyle = {
        margin: '5px 0', // Adjust as needed for your design
        // paddingLeft: '20px', // Adjust as needed for your design
        textAlign: 'center' // Ensures that all text is aligned uniformly
    };
    const formattedPrice = price && price.toLocaleString();
    let className = "col-3_md-12 feature unify";
    if (type === "monthly") {
        className = "col-4_md-12 feature unify monthly";
    }

    return (
        <div className={className} key={id}>
            <div className="image"><img alt={title} src={image} /></div>
            <div className="headline">{title}</div>
            <div className="body">{description}</div>
            {points && points.map((point, index) => (
                <p className="body" key={`${id}-${index}`} style={pointStyle}>{point}</p>
            ))}
            {price && <a href="/contact">{`Price: $${formattedPrice}`}</a>}
        </div>
    )
}


export default Services;