import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./components/scroll/ScrollToTop";

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-11079987946'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
