import React from "react";
import "./WhyChooseTysonWorks.css";

const WhyChooseTysonWorks = () => {
  return (
    <div id="why-choose-tysonworks" className="grid section">
      <div className="col-12_sm-12">
        <h2 className="head">Why Choose TysonWorks</h2>
      </div>
      <div className="col-12_sm-12">
        <div className="body">
          <p>
            Choose TysonWorks to transform your cloud infrastructure and drive your business forward.
          </p>
          <ul>
            <li>Deep cloud expertise across multiple platforms</li>
            <li>Strong focus on privacy and compliance in cloud solutions</li>
            <li>Commitment to the best practices</li>
            <li>Proven track record of successful implementations</li>
            <li>Dedicated support channels</li>  
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseTysonWorks;
