import React from "react";
import { Link } from "react-router-dom";

import LOGO_WHITE from "../../assets/images/tw-logo1.png";

const Header = (props) => {
    return (
        <div id="header" className={props.headerClass}>
            <div className="grid">
                <div id="nav" className="col-12">
                    <div className="left">
                        <Link to="/" onClick={onMenuClose}><img alt="" src={LOGO_WHITE} /><img alt="" src={LOGO_WHITE} /></Link>
                            <div className="hamburger-controls">
                                <a className="item open" onClick={onMenuOpen} href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" onClick={onMenuOpen}>
                                        <title>Menu</title>
                                        <path d="M170.666 170.666h682.668v136.534h-682.668zm0 273.068h682.668v136.532h-682.668zm0 273.066h682.668v136.534h-682.668z"></path>
                                    </svg>
                                </a>
                                <a className="close-icon" onClick={onMenuClose} href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" className="svg-icon">
                                        <title>Close</title>
                                        <path d="M439.604 512l-253.39-253.388 72.396-72.396 253.39 253.388 253.388-253.388 72.396 72.396-253.388 253.388 253.388 253.388-72.396 72.396-253.388-253.388-253.388 253.388-72.396-72.396 253.388-253.388z"></path>
                                    </svg>
                                </a>
                            </div>
                    </div>
                    <div className="right">
                        <ul>
                            <li><Link to="/about" onClick={onMenuClose}>About</Link></li>
                            <li><Link to="/services" onClick={onMenuClose}>Services</Link></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://medium.com/tysonworks" onClick={onMenuClose}>Blog</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" onClick={onMenuClose} href="https://linkedin.com/company/tysonworks">Career</a></li>
                            <li><Link to="/contact" onClick={onMenuClose}>Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

function onMenuOpen(){
    document.body.classList.add('menu-open');
    return false;
}
function onMenuClose(){
    document.body.classList.remove('menu-open');
    return false;
}

export default Header;