import React from "react";
import { useHistory } from 'react-router-dom';
import "./Splash.css";
import APN_BADGE from "../../assets/images/apn-badge.png"
import MAIN_IMAGE from "../../assets/images/business.svg";

const APN_LISTING = "https://aws.amazon.com/partners/find/partnerdetails/?id=0010h00001aCbizAAC";
const IQ_LISTING = "https://iq.aws.amazon.com/c/tysonworks";

const Splash = (props) => {
    return (
        <div id="jumbotron">
            <div className="grid" id="below-nav">
                <div className="col-12"></div>
                <div className="col-6_md-12" id="left-panel">
                    <div className="text">
                        <div className="head">Enhance Your Cloud Journey</div>
                        <div className="body">
                            <div className="service-list">We help companies to design, build, deploy, and manage complex AWS Cloud architectures on time and within budget</div>
                            <br />
                            <br />
                            <div id="splash-section" className="row">
                                <ul id="services" className="service-list">
                                    <li>Monthly Cloud Management</li>
                                    <li>Cloud Architecture</li>
                                    <li>DevOps</li>
                                    <li>Compliance Readiness(HIPAA & SOC2)</li>
                                    <li>AWS Marketplace Integration</li>
                                    <li>Cost Optimization</li>
                                </ul>
                                <a href={IQ_LISTING} target="_blank" rel="noopener noreferrer"><img alt="" id="apn-badge" src={APN_BADGE}></img></a>
                            </div>
                            <div className="row">
                                <ServicesButton />
                                <CalendlyPopup />
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div id="jumbotron-img" className="hero">
                        <div className="img"><img alt="" src={MAIN_IMAGE} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function CalendlyPopup() {
    function handleClick(e) {
        e.preventDefault();
        window.Calendly.initPopupWidget({ url: 'https://calendly.com/tysonworks/30mins' })
    }

    return (
        <div id='calendly-section'>
            <button className="button-solid" onClick={handleClick}>Schedule Free Consultation</button>
        </div>
    );
}

function ServicesButton() {
    const history = useHistory();

    const navigateToServices = () => {
        history.push('/services');
    };

    return (
        <div id='services-section'>
            <button className="button-solid" onClick={navigateToServices}>Explore Our Services</button>
        </div>
    );
}

export default Splash;