import React from "react";
import "./Company.css";
import ABOUT_IMAGE from "../../assets/images/about-image.png";

const Company = () => {
  return (
    <div id="company">
      <div id="company-top" className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">We’re here to enhance your cloud journey</h2>
        </div>
        <div className="col-12_sm-12">
          <div>
            <p className="body">
              Our mission is to break down the complexity of the cloud and provide a whole new level of flexibility and stability. We are here to enhance your cloud journey, so your company can evolve at a faster rate.
            </p>
            <p className="body">
              Cloud computing can unlock new opportunities for businesses, and many companies are trying to figure out how they can benefit from the cloud. At TysonWorks, you’ll be working hand in hand with cloud experts who will apply years of experience and best practices to your cloud implementation. We will guide you through the different cloud offerings and help your company understand the effectiveness of the cloud.
            </p>
            <p className="body">
              At TysonWorks, we provide a comprehensive range of professional services to help organizations achieve their business objectives efficiently and cost-effectively. Our expertise includes Cloud Management, Cloud Migration, Serverless Architecture, Cost Optimization, and DevOps. We are dedicated to helping organizations make the most of their AWS investment.
            </p>
            <img className="about-image" alt="" src={ABOUT_IMAGE} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;